import React, { useState } from 'react'

import { ProgressChart } from './ProgressChart'
import { RewardActive, RewardInactive, RewardRedeemed } from './Rewards'

type Params = {
  reward: {
    type: string
    target: number
    currentState: {
      leftToTarget: number
      available: boolean
      messages: {
        rewardValueMessage: string
        rewardLabelMessage: string
        nextRewardMessage: string
      }
    }
  }
  birthdayRewardRedeemed: boolean
}

export const Reward = ({ reward, birthdayRewardRedeemed }: Params) => {
  const {
    type,
    target,
    currentState: {
      leftToTarget,
      available,
      messages: { rewardValueMessage, rewardLabelMessage, nextRewardMessage }
    }
  } = reward

  const [isModalOpen, setIsModalOpen] = useState(false)

  // Extra details modal
  const openModal = () => {
    setIsModalOpen(true)
  }
  const closeModal = () => {
    setIsModalOpen(false)
  }

  const isBirthdayReward = type === 'BIRTHDAY'
  const isRedeemedBirthdayReward =
    !available && isBirthdayReward && birthdayRewardRedeemed

  return (
    <div>
      {leftToTarget > 0 && reward.type !== 'ITEM' && (
        <div className='pb-6'>
          <ProgressChart
            value={target - leftToTarget}
            max={target}
            label='reward'
            withLock={true}
          />
          <p className='type-default text-secondary text-center pt-6'>
            {nextRewardMessage + '!'}
          </p>
        </div>
      )}
      {available && (
        <RewardActive
          title={rewardValueMessage}
          subtitle={rewardLabelMessage}
          // don't show details modal for birthday reward
          details={
            isBirthdayReward
              ? undefined
              : `Redeem this reward online or in-store for ${rewardLabelMessage}.`
          }
          showModal={isBirthdayReward ? undefined : isModalOpen}
          openModal={isBirthdayReward ? undefined : openModal}
          closeModal={isBirthdayReward ? undefined : closeModal}
        />
      )}
      {isRedeemedBirthdayReward && (
        <RewardRedeemed
          title={'Redeemed'}
          subtitle={rewardLabelMessage}
          // don't show details modal for birthday reward
          details={undefined}
          showModal={undefined}
          openModal={undefined}
          closeModal={undefined}
        />
      )}
      {!available && !isBirthdayReward && (
        <RewardInactive
          title={rewardValueMessage}
          subtitle={rewardLabelMessage}
          details={nextRewardMessage}
          showModal={isModalOpen}
          openModal={openModal}
          closeModal={closeModal}
        />
      )}
    </div>
  )
}
