import React from 'react'

import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'

export const Loading = () => {
  return (
    <div className='w-full min-h-screen flex items-start justify-center'>
      <div
        role='progressbar'
        aria-label='loading icon'
        aria-valuetext='Loading'
        className='pt-24'
      >
        <MerryGoRound size={MerryGoRound.Size.md} />
      </div>
    </div>
  )
}
