import { banquetSingleSpaReact, BanquetProps } from 'banquet-runtime-modules'

import { ThemeManager } from '@toasttab/banquet-theme-manager'

import { themes } from '@local/themes'

import { AppWithFeatureFlag } from './app/AppWithFeatureFlag'
import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  cssScope: 'data-lwr',
  rootComponent: AppWithFeatureFlag,
  portalContainers: ['banquetPortalsContainer'],

  sentry: {
    publicKey: '9e4d7f2981a240678514a0f123cdd098@o37442',
    projectId: '1411128',
    releaseVersion: process.env.PKG_VERSION
  },

  theme: new ThemeManager((props: BanquetProps) => {
    if (props.isNextBuffetTheme) {
      return themes.next
    }
    return themes.current
  })
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'loyalty-web'
