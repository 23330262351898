import React from 'react'

import { ArrowForwardIcon } from '@toasttab/buffet-pui-icons'

import { BoxFooter } from './Box'
import { ProgressChart } from './ProgressChart'
import {
  useLoyaltyContext,
  getNumberOfSubmittedFields,
  hasCompleteProfile
} from '../../context/LoyaltyContext'

export const CompleteProfileBoxFooter = () => {
  const { userDetailsModal, openUserDetailsModal } = useLoyaltyContext()

  if (hasCompleteProfile(userDetailsModal)) {
    return null
  }

  return (
    <BoxFooter>
      <div
        id='lw-click-complete-profile-footer'
        className='group p-6 cursor-pointer'
        onClick={openUserDetailsModal}
        role='button'
        tabIndex='0'
        aria-label='Complete your profile'
      >
        <ProgressChart
          value={getNumberOfSubmittedFields(userDetailsModal)}
          max={6}
          label='profile'
        />
        <p className='type-default text-link font-bold group-hover:underline pt-4'>
          Complete your{' '}
          <span className='whitespace-no-wrap'>
            profile{' '}
            <ArrowForwardIcon
              accessibility='decorative'
              aria-label='complete your profile'
            />
          </span>
        </p>
        <p className='type-default text-default'>
          Help us learn how to customize your guest experience! Share your
          birthday and we'll help you celebrate with a special reward. We may
          also send you additional promotions, and personalize your dine-in
          experience.
        </p>
      </div>
    </BoxFooter>
  )
}
