import React from 'react'

import cx from 'classnames'

import { Button, IconButton } from '@toasttab/buffet-pui-buttons'
import { DeleteIcon } from '@toasttab/buffet-pui-icons'

import { useLoyaltyContext } from '../../context/LoyaltyContext'
import { displayCreditCardName } from '../../utils/functions'

export const LinkedCards = () => {
  const { cardDetails, openCreditCardModal, showMaxCCLinks } =
    useLoyaltyContext()

  const cards = cardDetails?.ccMetaData
  const validCards = cards.filter((i) => i && !i.deleted)

  return (
    <div className='px-6 pt-6'>
      <p className='type-default text-default'>
        You earn automatically every time you use a linked payment card.
        {showMaxCCLinks &&
          ' There is a maximum of five cards that can be linked to your loyalty account.'}
      </p>
      {validCards.length ? (
        <ul>{validCards.map(createCardItem(openCreditCardModal))}</ul>
      ) : (
        <p className='type-default text-default pt-4'>
          Looks like there's no linked cards.
        </p>
      )}
    </div>
  )
}

const createCardItem = (openCreditCardModal) => (card) => {
  const { cardType, lastFourDigits, hasExpired, verified } = card

  return (
    <li
      key={`linked-item-${cardType}-${lastFourDigits}`}
      className={cx('py-6 border-b type-default', {
        'text-default': !hasExpired,
        'text-secondary': hasExpired
      })}
    >
      <div className='flex justify-between items-center'>
        <div className='pr-2'>
          <div className='font-bold'>{displayCreditCardName(cardType)}</div>
          <div aria-label={`card ending with ${lastFourDigits}`}>
            <span className='whitespace-no-wrap pr-2' aria-hidden={true}>
              •••• •••• •••• {lastFourDigits}
            </span>
            {hasExpired && 'expired'}
          </div>
        </div>
        <div className='flex flex-wrap items-center justify-end'>
          {!verified && !hasExpired && (
            <Button
              id='lw-click-linked-cards-verify'
              size={Button.Size.sm}
              onClick={() => {
                openCreditCardModal(card, true)
              }}
            >
              verify
            </Button>
          )}
          <IconButton
            id='lw-click-linked-cards-remove'
            className='ml-2 -mr-3'
            icon={
              <DeleteIcon
                accessibility='semantic'
                aria-label='click to remove card'
              />
            }
            aria-label='remove card'
            onClick={() => openCreditCardModal(card, false)}
          />
        </div>
      </div>
    </li>
  )
}
