import React from 'react'

import cx from 'classnames'

import { Button } from '@toasttab/buffet-pui-buttons'
import {
  GiftCardIcon,
  LockLockedIcon,
  DiamondIcon
} from '@toasttab/buffet-pui-icons'
import { Modal } from '@toasttab/buffet-pui-modal'

export const createIcon = (iconName, attributes) => {
  const iconMap = {
    giftCard: (
      <GiftCardIcon
        accessibility='semantic'
        aria-label='reward is redeemed'
        {...attributes}
      />
    ),
    lockLocked: (
      <LockLockedIcon
        accessibility='semantic'
        aria-label='reward is inactive'
        {...attributes}
      />
    ),
    star: (
      <DiamondIcon
        accessibility='semantic'
        aria-label='reward is active'
        {...attributes}
      />
    )
  }

  return iconMap[iconName]
}

const rewardBase =
  (iconName, borderStyle, mainStyle, secondaryStyle) =>
  ({ title, subtitle, details, showModal, openModal, closeModal }) => {
    return (
      <>
        <div
          className={cx(
            'rounded-md p-6 mt-2 flex items-baseline flex-nowrap cursor-pointer',
            borderStyle
          )}
          onClick={openModal}
        >
          {createIcon(iconName, {
            testId: `reward-icon-${iconName}`,
            className: cx('fill-current mr-3', mainStyle)
          })}
          <p
            className={cx(
              'type-default font-bold flex-grow mr-2 my-1 overflow-ellipsis line-clamp-2',
              mainStyle
            )}
          >
            {title}
          </p>
          {subtitle && (
            <p className={cx('type-default whitespace-nowrap', secondaryStyle)}>
              {subtitle}
            </p>
          )}
        </div>

        {showModal && (
          <Modal isOpen={true} onRequestClose={closeModal}>
            <Modal.Body className='flex items-center'>
              <div
                className='flex items-center justify-center rounded-full bg-info-0 text-link w-14 h-14'
                style={{ minWidth: '3.5rem' }}
              >
                <DiamondIcon
                  accessibility='decorative'
                  aria-label='reward icon'
                />
              </div>
              <p className='text-primary-75 type-default font-bold mt-4'>
                {title}
              </p>
              <p>{subtitle}</p>
              <p className='mt-4'>{details}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button className='w-full' onClick={closeModal}>
                Done
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </>
    )
  }

export const RewardActive = rewardBase(
  'star',
  'shadow-lg',
  'text-primary-75',
  'text-secondary'
)

export const RewardRedeemed = rewardBase(
  'giftCard',
  'shadow-lg',
  'text-secondary',
  'text-secondary'
)

export const RewardInactive = rewardBase(
  'lockLocked',
  'border-2 border-dashed border-gray-25',
  'text-disabled',
  'text-disabled'
)
