import React from 'react'

import { BanquetFeatureFlagsProvider } from '@toasttab/banquet-feature-flags'

import { App } from './App'

/**
 * @deprecated This is temporary and will only be used for the rollout of the new redesigned tracking page
 * BanquetFeatureFlagsProvider is deprecated and shouldn't be used but as this spa doesn't need
 * feature flags, we will only use this for a short period
 */
export const AppWithFeatureFlag = () => (
  <BanquetFeatureFlagsProvider>
    <App />
  </BanquetFeatureFlagsProvider>
)
