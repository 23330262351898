import React from 'react'

import cx from 'classnames'

export const Terms = ({ className, isReceivingMarketing = false }) => {
  if (isReceivingMarketing === true) {
    return (
      <p
        className={cx('type-subhead text-justify text-secondary', className)}
        aria-label='sms-terms-and-conditions'
      >
        By opting in, you agree to receive automated and personalized marketing
        text messages at this number. Consent not a condition of purchase.
        Message and data rates may apply, frequency varies. Reply STOP to opt
        out. Subject to{' '}
        <a
          id='lw-click-terms-service'
          href='https://pos.toasttab.com/terms-of-service'
          rel='noopener noreferrer'
          target='_blank'
          className='text-primary-75'
        >
          Toast's Terms
        </a>{' '}
        and{' '}
        <a
          id='lw-click-terms-privacy'
          href='https://pos.toasttab.com/privacy'
          rel='noopener noreferrer'
          target='_blank'
          className='text-primary-75'
        >
          Privacy Statement
        </a>
        .
      </p>
    )
  }

  return (
    <p
      className={cx('type-subhead text-center text-secondary', className)}
      aria-label='terms-and-conditions'
    >
      Information will be processed pursuant to Toast's{' '}
      <a
        id='lw-click-terms-service'
        href='https://pos.toasttab.com/terms-of-service/#diner-tos'
        rel='noopener noreferrer'
        target='_blank'
        className='text-primary-75'
      >
        Guest Terms of Service
      </a>{' '}
      and{' '}
      <a
        id='lw-click-terms-privacy'
        href='https://pos.toasttab.com/privacy'
        rel='noopener noreferrer'
        target='_blank'
        className='text-primary-75'
      >
        Privacy Statement
      </a>
      .
    </p>
  )
}
