import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const GiftCardIcon = React.forwardRef(function GiftCardIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M9.261 8.31a4.992 4.992 0 00-1.187-3.269c-.723-.754-1.92-1.61-3.03-1.1a1.485 1.485 0 00-.338 2.416 6.415 6.415 0 004.527 1.954h.028zm0 0a4.993 4.993 0 011.188-3.269c.723-.754 1.92-1.61 3.03-1.1a1.485 1.485 0 01.338 2.416A6.416 6.416 0 019.29 8.311h-.029zM3 14.263h18M9.233 8.313v11.9M3 8.317h18v11.892H3V8.317z'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
