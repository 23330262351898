import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const RestaurantIcon = React.forwardRef(function RestaurantIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M7.5 3v4.197m0 0a2.247 2.247 0 01-3.11 2.075A2.247 2.247 0 013 7.197V6.135m4.5 1.062a2.247 2.247 0 003.11 2.075A2.246 2.246 0 0012 7.197M12 3v4.197m0 0a2.247 2.247 0 003.11 2.075 2.246 2.246 0 001.39-2.075M16.5 3v4.197m0 0a2.247 2.247 0 003.11 2.075A2.246 2.246 0 0021 7.197V6.135M4.997 3h14.006A1.997 1.997 0 0121 4.997v15.004a.999.999 0 01-.999.999H4A.999.999 0 013 20.001V4.997A1.997 1.997 0 014.997 3zm2.252 9.692h2.188c.552 0 .999.447.999.999v1.277a.999.999 0 01-.999.998H7.25a.999.999 0 01-.999-.998V13.69c0-.552.448-.999 1-.999zm7.314 0h2.188a.999.999 0 01.998.999V21h-4.185v-7.31a.998.998 0 01.999-.998z'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeMiterlimit={10}
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
