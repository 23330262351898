import { LoyaltyView } from './LoyaltyView'
import { LoyaltyContextProvider } from '../../context/LoyaltyContext'

export const LoyaltyPage = () => {
  return (
    <LoyaltyContextProvider>
      <LoyaltyView />
    </LoyaltyContextProvider>
  )
}
