import React from 'react'

import { TextInput as TI } from '@toasttab/buffet-pui-text-input'

export const TextInput = ({
  ariaLabel,
  placeholder,
  disabled,
  value,
  onChange
}) => {
  const onChangeInput = (e) => {
    onChange(e?.target?.value)
  }

  return (
    <TI
      aria-label={ariaLabel}
      placeholder={placeholder}
      disabled={disabled}
      value={value}
      onChange={onChangeInput}
      className={'rounded-none'}
    />
  )
}
