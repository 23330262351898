import React from 'react'

import { formatCurrency } from '@toasttab/buffet-pui-number-utilities'

import { BoxFooter } from './Box'
import { useLoyaltyContext } from '../../context/LoyaltyContext'
import { numberToFixed } from '../../utils/functions'

export const GiftCardBalance = () => {
  const {
    cardDetails,
    isInternationalRestaurant,
    restaurant: { currency, locale }
  } = useLoyaltyContext()

  // eslint-disable-next-line @toasttab/buffet/hard-coded-currencies
  const balance = formatCurrency(
    {
      amount: numberToFixed(cardDetails?.giftCardBalance, 2),
      currency
    },
    locale
  )
  const link = cardDetails?.giftCardAddValueLink

  return (
    <>
      <div className='px-6 py-8'>
        <div className='max-w-sm mx-auto'>
          <h3 className='type-headline-3 text-default font-bold text-center'>
            {balance}
          </h3>
          <p className='type-default text-secondary text-center'>
            Gift Card Balance
          </p>
        </div>
      </div>
      {!isInternationalRestaurant && (
        <BoxFooter>
          <a
            id='lw-click-gift-card-add-balance'
            href={link}
            rel='noopener noreferrer'
            target='_blank'
          >
            <p className='type-default font-bold text-link text-center py-5 px-6'>
              Add to balance
            </p>
          </a>
        </BoxFooter>
      )}
    </>
  )
}
