import React from 'react'

import { NumberInput } from '@toasttab/buffet-pui-text-input'

export const TelephoneInput = ({ disabled, value, onChange }) => {
  const onChangeInput = (e) => {
    onChange(e?.value)
  }

  const isInvalid = !disabled && value && value.length !== 10

  return (
    <NumberInput
      aria-label='phone'
      format='( ### ) ###-####'
      allowEmptyFormatting={true}
      mask='_'
      type='tel'
      disabled={disabled}
      invalid={isInvalid}
      className={value ? '' : 'text-gray-75'}
      value={value}
      onChange={onChangeInput}
    />
  )
}
