import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const PlaceSettingIcon = React.forwardRef(function PlaceSettingIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M5.04 3.675v4.55c0 .85-.68 1.55-1.52 1.55m0 0c-.84 0-1.52-.69-1.52-1.55v-4.55m1.52 6.1v10.55M22 13.975h-2.71s-.45-8.89 2.29-10.21c.19-.09.42.05.42.27v16.24m-15.35-4.4a7.286 7.286 0 005.35 2.34 7.3 7.3 0 005.03-2.01m-.23-8.83a6.53 6.53 0 00-4.8-2.1c-1.9 0-3.6.81-4.8 2.1'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
