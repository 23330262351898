import React from 'react'

import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel
} from '@toasttab/buffet-pui-tabs'

import { LinkedCards } from './LinkedCards'
import { RecentActivity } from './RecentActivity'
import { useLoyaltyContext } from '../../context/LoyaltyContext'

export const LoyaltyTabs = () => {
  const { cardDetails } = useLoyaltyContext()
  const hasCreditCardItems = cardDetails?.ccMetaData?.length

  return (
    <Tabs className='py-8'>
      <TabList position='center' block>
        {[
          hasCreditCardItems && (
            <Tab key='linked-cards'>
              <span id='lw-click-tab-linked-cards'>Linked Cards</span>
            </Tab>
          ),
          <Tab key='recent-activity'>
            <span id='lw-click-tab-recent-activity'>Recent Activity</span>
          </Tab>
        ].filter((t) => t)}
      </TabList>
      <TabPanels>
        {[
          hasCreditCardItems && (
            <TabPanel key='linked-cards-content'>
              <LinkedCards />
            </TabPanel>
          ),
          <TabPanel key='loyalty-history-content'>
            <RecentActivity />
          </TabPanel>
        ].filter((tp) => tp)}
      </TabPanels>
    </Tabs>
  )
}
