import React from 'react'

import { Route, Routes, BrowserRouter } from 'react-router-dom'

import { useDocumentTitle } from '@toasttab/use-document-title'

import { LoyaltyPage } from '../components/LoyaltyPage/LoyaltyPage'

export const App = () => {
  useDocumentTitle({ title: 'Loyalty Account' })

  return (
    <div id='data-lwr'>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path='/loyalty/welcome/:cardToken'
            element={<LoyaltyPage />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  )
}
