import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const OrdersIcon = React.forwardRef(function OrdersIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M7.95 21.95h-5c-.6 0-1-.4-1-1v-18c0-.6.4-1 1-1h10.7c.6 0 1 .4 1 1v7.6m7.4 5.6h-11.3m2.3 2.9h1.6m-12.6-.7h6m13 3.6h-9.3c-.6 0-1-.4-1-1v-6.4c0-.6.4-1 1-1h9.3c.6 0 1 .4 1 1v6.4c0 .6-.5 1-1 1z'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeMiterlimit={10}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
