import React from 'react'

import { Box } from './Box'
import { ContactBoxFooter } from './ContactBoxFooter'
import { Header } from './Header'
import { Layout } from './Layout'

export const LoyaltyError = ({ message }) => {
  return (
    <Layout>
      <Box>
        <Header />
        <h4 className='type-headline-4 text-default font-bold text-center px-6 pt-6 pb-4'>
          {message}
        </h4>
        <p className='type-large text-secondary text-center px-6 pb-10'>
          Please contact us with any questions
        </p>
        <ContactBoxFooter />
      </Box>
    </Layout>
  )
}
