import React from 'react'

import cx from 'classnames'

export const Box = ({ className, children }) => {
  return (
    <div className={cx('bg-white shadow-lg rounded-lg mb-8', className)}>
      {children}
    </div>
  )
}

export const BoxFooter = ({ children }) => {
  return <div className='bg-info-0 rounded-b-lg'>{children}</div>
}
