import * as React from 'react'

/**
 * useDocumentTitle - Set the page title on mount and restore on unmount
 */

export interface UseDocumentTitleProps {
  title: string
  section?: string
  org?: string
}

export const getTitle = ({ title, section, org }: UseDocumentTitleProps) =>
  [title, section, org].filter(Boolean).join(' - ')

export const useDocumentTitle = ({
  title,
  section,
  org = 'Toast'
}: UseDocumentTitleProps) => {
  const [originalTitle] = React.useState(document.title)
  const newTitle = getTitle({ title, section, org })
  React.useEffect(() => {
    document.title = newTitle
    return () => {
      document.title = originalTitle
    }
  }, [originalTitle, newTitle])
}
