import { useState, useEffect } from 'react'

import * as R from 'ramda'
import { useParams, useSearchParams } from 'react-router-dom'
import { createContainer } from 'unstated-next'

import { api } from '../../src/services/api'
import { getSearchObject, scrollTop } from '../../src/utils/functions'
import { trackEvent } from '../utils/tracking'

export const LoyaltyContext = createContainer(() => {
  const [searchParams] = useSearchParams()
  const { cardToken } = useParams()

  const [loading, setLoading] = useState(true)
  const [initialErrorMessage, setInitialErrorMessage] = useState(undefined)
  const [loyaltyData, setLoyaltyData] = useState({})
  const [userDetailsModal, setUserDetailsModal] = useState({})
  const [creditCardModal, setCreditCardModal] = useState({})
  const [showQrCode, setShowQrCode] = useState(false)
  const [showAppDownload, setShowAppDownload] = useState(false)
  const [subscribeToSmsMarketing, setSubscribeToSmsMarketing] = useState(false)

  useEffect(() => {
    const paymentId = searchParams.get('paymentId') ?? null
    const ccVerifyToken = searchParams.get('ccVerifyToken') ?? null

    api
      .get(cardToken, paymentId, ccVerifyToken)
      .then((data) => {
        setLoyaltyData(
          R.mergeDeepRight(data, {
            restaurant: {
              locale: data?.restaurant?.locale?.replace('_', '-') || 'en-US'
            }
          })
        )
        setInitialUserDetailsModal(data)
        if (showInitialQRCode(data.cardDetails)) {
          setShowQrCode(true)
        }
        setShowAppDownload(
          data.showAppDownloadCTA &&
            !data.isInternationalRestaurant &&
            !isNativeAppClient()
        )
      })
      .catch((errorMessage) => {
        setInitialErrorMessage(errorMessage)
        trackEvent('initial load error')
      })
      .finally(() => setLoading(false))
  }, [cardToken, searchParams])

  const setInitialUserDetailsModal = (data) => {
    setUserDetailsModal({
      open: !data.cardDetails?.hasSignedUpBirthdayRewards,
      firstNameSubmitted: !!data.cardDetails?.hasFirstName,
      firstName: '',
      lastNameSubmitted: !!data.cardDetails?.hasLastName,
      lastName: '',
      emailSubmitted: data.cardDetails?.hasEmail,
      email: '',
      phoneNumberSubmitted: data.cardDetails?.hasPhone,
      phoneNumber: '',
      birthdayMonthSubmitted: data.cardDetails?.hasSignedUpBirthdayRewards,
      birthdayMonth: '',
      birthdayDaySubmitted: data.cardDetails?.hasSignedUpBirthdayRewards,
      birthdayDay: undefined
    })

    trackEvent({
      name: 'Phone Number Field',
      properties: { isPresent: !data.cardDetails?.hasPhone }
    })
  }

  const openUserDetailsModal = () => {
    setUserDetailsModal((current) => ({
      ...current,
      open: true
    }))
  }

  const closeUserDetailsModal = () => {
    setUserDetailsModal((current) => ({
      ...current,
      open: false,
      loading: false,
      errorMessage: undefined
    }))
  }

  const onChangeUserDetailsModal = (key) => (value) => {
    setUserDetailsModal((current) => ({
      ...current,
      [key]: value
    }))
  }

  const submitUserDetailsModal = () => {
    setUserDetailsModal((current) => ({
      ...current,
      loading: true,
      errorMessage: undefined
    }))

    const token = loyaltyData.cardDetails.cardToken
    let payload = createUserPayload(userDetailsModal)

    if (loyaltyData.smsMarketingAccountEnabled) {
      payload = {
        ...payload,
        subscribeToSmsMarketing
      }
    }

    api
      .updateUser(token, payload)
      .then(() => {
        setUserDetailsModal((current) => {
          const updatedPayload = updateSubmitted(payload, current)
          trackEvent({
            name: 'user details submit success',
            properties: {
              completedProfile: hasCompleteProfile(updatedPayload),
              subscribeToSmsMarketing
            }
          })
          return updatedPayload
        })
        closeUserDetailsModal()
      })
      .catch((errorMessage) => {
        setUserDetailsModal((current) => ({
          ...current,
          loading: false,
          errorMessage
        }))
        trackEvent({ name: 'user details submit error' })
      })
  }

  const openCreditCardModal = (selectedCardData, verifying) => {
    setCreditCardModal((current) => ({
      ...current,
      open: true,
      selectedCardData,
      verifying
    }))
  }

  const closeCreditCardModal = () => {
    setCreditCardModal({ open: false })
  }

  const creditCardAction = () => {
    const { selectedCardData, verifying } = creditCardModal
    const { cardId, webLinkToken } = selectedCardData

    setCreditCardModal((current) => ({
      ...current,
      loading: true,
      errorMessage: undefined
    }))

    const token = loyaltyData.cardDetails.cardToken
    const apiCall = verifying ? api.verifyCard : api.removeCard

    apiCall(cardId, token, webLinkToken)
      .then(() => {
        const update = verifying ? { verified: true } : { deleted: true }
        updateCCMetaData({
          ...selectedCardData,
          ...update
        })
        closeCreditCardModal()
        trackEvent({
          name: `credit card ${verifying ? 'verify' : 'remove'} success`
        })
      })
      .catch((errorMessage) => {
        setCreditCardModal((current) => ({
          ...current,
          loading: false,
          errorMessage
        }))
        trackEvent({
          name: `credit card ${verifying ? 'verify' : 'remove'} error`
        })
      })
  }

  const updateCCMetaData = (updatedCard) => {
    setLoyaltyData((current) => {
      const updatedMetaData = current.cardDetails.ccMetaData.map((card) => {
        if (card.cardId === updatedCard.cardId) {
          return updatedCard
        }
        return card
      })
      return {
        ...current,
        cardDetails: {
          ...current.cardDetails,
          ccMetaData: updatedMetaData
        }
      }
    })
  }

  const toggleQRCode = () => {
    scrollTop()
    setShowQrCode((current) => !current)
  }

  return {
    loading,
    initialErrorMessage,
    ...loyaltyData,
    userDetailsModal,
    openUserDetailsModal,
    closeUserDetailsModal,
    onChangeUserDetailsModal,
    submitUserDetailsModal,
    setSubscribeToSmsMarketing,
    creditCardModal,
    openCreditCardModal,
    closeCreditCardModal,
    creditCardAction,
    showQrCode,
    toggleQRCode,
    showAppDownload,
    subscribeToSmsMarketing
  }
})

const LoyaltyContextProvider = LoyaltyContext.Provider
const useLoyaltyContext = LoyaltyContext.useContainer
export { LoyaltyContextProvider, useLoyaltyContext }

const showInitialQRCode = (cardDetails) => {
  const searchObject = getSearchObject()
  return !!searchObject.showQRCode && cardDetails?.cardNumber
}

const isNativeAppClient = () => {
  const appClient = getSearchObject().app_client
  return appClient === 'ios' || appClient === 'android'
}

const PAYLOAD_KEYS = [
  'email',
  'phoneNumber',
  'firstName',
  'lastName',
  'birthdayMonth',
  'birthdayDay'
]

const createUserPayload = (userDetailsModal) => {
  return Object.keys(userDetailsModal).reduce((acc, key) => {
    if (PAYLOAD_KEYS.includes(key) && userDetailsModal[key]) {
      return { ...acc, [key]: userDetailsModal[key] }
    }
    return acc
  }, {})
}

const updateSubmitted = (payload, currentUserDetailsModal) => {
  const submittedKeys = Object.keys(payload)
  const submittedUpdate = submittedKeys.reduce((acc, key) => {
    return { ...acc, [`${key}Submitted`]: true }
  }, {})
  return {
    ...currentUserDetailsModal,
    ...submittedUpdate
  }
}

export const getNumberOfSubmittedFields = (userDetailsModal) => {
  return [
    userDetailsModal.firstNameSubmitted,
    userDetailsModal.lastNameSubmitted,
    userDetailsModal.emailSubmitted,
    userDetailsModal.phoneNumberSubmitted,
    userDetailsModal.birthdayMonthSubmitted,
    userDetailsModal.birthdayDaySubmitted
  ].filter((s) => s).length
}

export const hasCompleteProfile = (userDetailsModal) => {
  const numberOfSubmitted = getNumberOfSubmittedFields(userDetailsModal)
  return numberOfSubmitted === 6
}
