import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const DiamondIcon = React.forwardRef(function DiamondIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M12 22L2.154 10.615l3.77-3.692h12.152l3.77 3.692L12 22zm0 0L7.577 10.615l4.054-3.384M12 22l4-11.385-4-3.384m9.538 3.384H2.461M12 2v2.462M6.692 3.23l1.77 1.73m9.269-1.73L16 4.96'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
