import React from 'react'

import { Reward } from './Reward'
import { useLoyaltyContext } from '../../context/LoyaltyContext'

export const PointsAndRewards = () => {
  const { cardDetails, rewards, rewardsBalanceText } = useLoyaltyContext()
  const { birthdayRewardRedeemed } = cardDetails

  return (
    <div className='px-6 pb-8 pt-10'>
      <div className='max-w-sm mx-auto'>
        <h3 className='type-headline-3 text-default font-bold text-center'>
          {rewardsBalanceText}
        </h3>
        <p className='type-default text-secondary text-center pb-6'>
          Loyalty Balance
        </p>

        {rewards?.map((reward, index) => (
          <Reward
            key={`${reward.type}-reward-${index}`}
            reward={reward}
            birthdayRewardRedeemed={birthdayRewardRedeemed}
          />
        ))}
      </div>
    </div>
  )
}
