import React from 'react'

import { BoxFooter } from './Box'
import { useLoyaltyContext } from '../../context/LoyaltyContext'

export const ContactBoxFooter = () => {
  const { restaurant } = useLoyaltyContext()

  const websiteUrl = restaurant?.restaurantWebsite
  const phoneNumber = restaurant?.restaurantPhone

  if (!websiteUrl && !phoneNumber) return null

  return (
    <BoxFooter>
      <div className='p-6'>
        {websiteUrl && (
          <a
            id='lw-click-contact-footer-website'
            href={websiteUrl}
            rel='noopener noreferrer'
            target='_blank'
          >
            <p className='type-large text-center text-link'>
              {displayUrl(websiteUrl)}
            </p>
          </a>
        )}
        {websiteUrl && phoneNumber && <div className='mt-3'></div>}
        {phoneNumber && (
          <a
            id='lw-click-contact-footer-phone'
            href={`tel:${phoneNumber}`}
            rel='noopener noreferrer'
            target='_blank'
          >
            <p className='type-large text-center text-link'>
              {displayPhone(phoneNumber)}
            </p>
          </a>
        )}
      </div>
    </BoxFooter>
  )
}

export const displayUrl = (url = '') => {
  const removedHttp = url.replace('https://', '').replace('http://', '')
  return removedHttp.endsWith('/') ? removedHttp.slice(0, -1) : removedHttp
}

export const displayPhone = (phone = '') => {
  if (phone && phone.length > 6) {
    return (
      phone.substring(0, 3) +
      '-' +
      phone.substring(3, 6) +
      '-' +
      phone.substring(6)
    )
  }
  return phone
}
