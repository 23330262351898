import React from 'react'

import {
  ArrowForwardIcon,
  OrdersIcon,
  RestaurantIcon
} from '@toasttab/buffet-pui-icons'

import { AddToAppleWalletButton } from './AddToAppleWalletButton'
import { QRCodeBoxFooter } from './QRCodeBoxFooter'
import { WaysToItem } from './WaysToItem'
import { useLoyaltyContext } from '../../context/LoyaltyContext'

export const WaysToRedeem = () => {
  const {
    isOOAvailable,
    restaurant,
    cardDetails,
    toggleQRCode,
    showAddToNativeWallet
  } = useLoyaltyContext()

  const shortUrl = restaurant?.shortUrl
  const website = restaurant?.restaurantWebsite
  const name = restaurant?.restaurantName
  const cardToken = cardDetails?.cardToken
  const cardNumber = cardDetails?.cardNumber

  return (
    <>
      <div className='p-6'>
        <p className='type-large text-default font-bold pb-2'>Ways to redeem</p>
        <p className='type-default text-secondary'>
          Rewards can be redeemed at checkout after connecting to a loyalty
          account.
        </p>
        {isOOAvailable && shortUrl && (
          <a
            id='lw-click-ways-to-redeem-oo'
            href={`/${shortUrl}/v3?utmSource=loyalty-web`}
            rel='noopener noreferrer'
            target='_blank'
            className='no-underline'
            aria-label='online ordering'
          >
            <WaysToItem
              icon={
                <OrdersIcon
                  accessibility='decorative'
                  aria-label='online order icon'
                />
              }
              title={
                <span className='whitespace-no-wrap'>
                  Online{' '}
                  <ArrowForwardIcon
                    accessibility='semantic'
                    aria-label='click to order online'
                  />
                </span>
              }
              subtitles={[
                'Create an online ordering account with the email or phone number linked to your loyalty account and log in to order.'
              ]}
              isLink={true}
            />
          </a>
        )}
        {website ? (
          <a
            id='lw-click-ways-to-redeem-website'
            href={website}
            rel='noopener noreferrer'
            target='_blank'
            className='no-underline'
            aria-label={`${name} website`}
          >
            <WaysToItem
              icon={
                <RestaurantIcon
                  accessibility='decorative'
                  aria-label='in store order icon'
                />
              }
              title={
                <span className='whitespace-no-wrap'>
                  In Store{' '}
                  <ArrowForwardIcon
                    accessibility='semantic'
                    aria-label='click to order in store'
                  />
                </span>
              }
              subtitles={[
                'Use a linked payment card to connect to your account automatically or present your digital loyalty card to staff.'
              ]}
              isLink={true}
            />
          </a>
        ) : (
          <WaysToItem
            icon={
              <RestaurantIcon
                accessibility='decorative'
                aria-label='order in store icon'
              />
            }
            title='In Store'
            subtitles={[
              'Use a linked payment card to connect to your account automatically or present your digital loyalty card to staff.'
            ]}
          />
        )}
        {showAddToNativeWallet && (
          <div className='pt-4 mx-auto flex justify-center'>
            <AddToAppleWalletButton
              testId='add-to-apple-wallet-ways-to-redeem'
              cardToken={cardToken}
            />
          </div>
        )}
      </div>
      {cardNumber && <QRCodeBoxFooter onClick={toggleQRCode} />}
    </>
  )
}
