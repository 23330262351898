import * as React from 'react'
import * as LaunchDarkly from 'launchdarkly-react-client-sdk'
import type { ProviderConfig } from 'launchdarkly-react-client-sdk/lib/types'
import {
  getCurrentEnvironment,
  ToastEnvironment
} from '@toasttab/current-environment'

export interface BanquetFeatureFlagsProviderProps
  extends Omit<ProviderConfig, 'clientSideID'> {
  children: React.ReactNode
  launchDarklyClientSideIDs?: Partial<
    Record<ToastEnvironment | 'local', string>
  >
}

const defaultLaunchDarklyClientIDs = {
  dev: '59f25a96d4a96f0acbd4189c',
  test: '59f25a96d4a96f0acbd4189c',
  prod: '59e4bfdd6292ac0ac9174386',
  preprod: '59f23d33a3862f0accd10d65',
  // just using same as preprod for now
  sandbox: '59f23d33a3862f0accd10d65'
}

/**
 * @deprecated Please add your feature flags at the web server and then retrieve them via `useBanquetProps`. See {@link <https://github.toasttab.com/toasttab/banquet-tools/blob/develop/packages/banquet-feature-flags/README.md>} for more info.
 */
export const BanquetFeatureFlagsProvider = ({
  children,
  launchDarklyClientSideIDs,
  ...props
}: BanquetFeatureFlagsProviderProps) => {
  const ldClientSideIDs = {
    ...defaultLaunchDarklyClientIDs,
    ...launchDarklyClientSideIDs
  }
  const environment = getCurrentEnvironment()
  return (
    <LaunchDarkly.LDProvider
      {...props}
      clientSideID={ldClientSideIDs.local || ldClientSideIDs[environment]}
    >
      {children}
    </LaunchDarkly.LDProvider>
  )
}

/**
 * @deprecated Please add your feature flags at the web server and then retrieve them via `useBanquetProps`. See {@link <https://github.toasttab.com/toasttab/banquet-tools/blob/develop/packages/banquet-feature-flags/README.md>} for more info.
 */
export const useFlags = LaunchDarkly.useFlags
