import React from 'react'

import { ArrowForwardIcon } from '@toasttab/buffet-pui-icons'

import { BoxFooter } from './Box'
import CelebrationIllustration from '../../images/celebration-illustration.svg'

export const BirthdayBoxFooter = ({ onClick }) => {
  return (
    <BoxFooter>
      <div
        id='lw-click-share-birthday-footer'
        className='group flex py-6 pl-6 pr-4 cursor-pointer'
        onClick={onClick}
        role='button'
        tabIndex='0'
        aria-label='Share your birthday'
      >
        <div className='mr-4 flex-grow'>
          <p className='type-default text-link font-bold group-hover:underline'>
            Share your{' '}
            <span className='whitespace-no-wrap'>
              birthday{' '}
              <ArrowForwardIcon
                accessibility='decorative'
                aria-label='share your birthday'
              />
            </span>
          </p>
          <p className='type-default text-default'>
            We'll help you celebrate with a special reward. We may also send you
            additional promotions, and personalize your dine-in experience.
          </p>
        </div>
        <div className='w-22 h-22 -my-2' style={{ minWidth: '5.5rem' }}>
          <CelebrationIllustration />
        </div>
      </div>
    </BoxFooter>
  )
}
