const removeZeros = (s) => s.replace('.00', '')

export const numberToFixed = (num, places) => {
  return num && !isNaN(num) ? num.toFixed(places) : '0'
}

export const numberFormatCurrency = (value = 0) => {
  return removeZeros(numberToFixed(value, 2))
}

export const scrollTop = () => {
  window.scrollTo(0, 0)
}

const VALID_KEYS = ['showQRCode', 'app_client']

export const getSearchObject = () => {
  const searchString = window.location.search
  const searchObject = Object.fromEntries(new URLSearchParams(searchString))

  return Object.keys(searchObject).reduce((acc, key) => {
    return VALID_KEYS.includes(key) ? { ...acc, [key]: searchObject[key] } : acc
  }, {})
}

export const displayCreditCardName = (cardName = '') => {
  switch (cardName) {
    case 'VISA':
      return 'Visa'
    case 'MASTERCARD':
      return 'Mastercard'
    case 'AMEX':
      return 'American Express'
    case 'DISCOVER':
      return 'Discover'
    case 'JCB':
      return 'JCB'
    case 'DINERS':
      return 'Diners'
    case 'CITI':
      return 'Citi'
    case 'MAESTRO':
      return 'Maestro'
    case 'LASER':
      return 'Laser'
    case 'SOLO':
      return 'Solo'
    case 'UNKNOWN':
      return 'Unknown'
    default:
      return cardName
  }
}
