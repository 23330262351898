import React from 'react'

import { Button } from '@toasttab/buffet-pui-buttons'
import {
  getCurrentEnvironment,
  ToastEnvironment
} from '@toasttab/current-environment'

import { useLoyaltyContext } from '../../context/LoyaltyContext'
import Sparkle from '../../images/sparkle.svg'
import { urls } from '../../utils/networkConstants'

export const AppDownload = () => {
  const { cardDetails, restaurant, rewardsConfig } = useLoyaltyContext()
  if (rewardsConfig?.accrualType === 'VISIT') return null

  const isProd = getCurrentEnvironment() === ToastEnvironment.PROD
  const baseUrl = isProd
    ? urls.prodToastTakeoutDynamicLink
    : urls.preprodToastTakeoutDynamicLink
  const redirectLinkBaseUrl = isProd ? urls.prodToasttab : urls.preprodToasttab

  const secureToken = cardDetails.cardToken
  const restaurantGuid = restaurant.restaurantGuid
  const redirectLinkParam =
    redirectLinkBaseUrl +
    `/loyalty/welcome/${secureToken}?restaurant_guid=${restaurantGuid}`

  const url = new URL(baseUrl)
  url.searchParams.append('link', redirectLinkParam)
  url.searchParams.append('utm_source', 'loyalty')
  url.searchParams.append('utm_medium', 'web')
  url.searchParams.append('utm_campaign', 'loyaltyWelcomePage')
  url.searchParams.append('isi', '1362180579') // apple app store ID
  url.searchParams.append('ibi', 'com.toasttab.consumer') // apple bundle ID
  url.searchParams.append('at', '1001IQNI') // affiliate token
  url.searchParams.append('ct', 'loyaltyWelcomePage') // campaign text
  url.searchParams.append('mt', '8') // media type
  url.searchParams.append('efr', '1') // skip app preview page
  url.searchParams.append('apn', 'com.toasttab.consumer') // android package name
  url.searchParams.append('pt', '1364164') // provider token
  url.searchParams.append('ofl', urls.downloadTheAppPage) // redirect link for devices not running android/ios

  return (
    <div data-testid='app-download-cta' className='px-6 pb-8'>
      <div className='max-w-sm mx-auto'>
        <div className='flex justify-center'>
          <Sparkle className='my-auto mx-4' />
          <p className='type-headline-5 text-default font-bold text-center'>
            Track your rewards
          </p>
          <Sparkle className='my-auto mx-4' />
        </div>
        <div className='flex justify-center'>
          <p className='type-headline-5 text-default font-bold text-center'>
            in the Local by Toast app
          </p>
        </div>
        <div className='pt-6 flex justify-center'>
          <a href={url.toString()}>
            <Button
              variant={Button.Variant.primary}
              testId='app-download-button'
            >
              Get the app
            </Button>
          </a>
        </div>
      </div>
    </div>
  )
}
