import axios from 'axios'

export const defaultErrorMessage =
  'Something went wrong. Please try again later.'

const handleError = (promise) =>
  promise
    .then((response) => {
      if (response?.status === 200) {
        return response?.data
      }
      throw defaultErrorMessage
    })
    .catch(() => {
      throw defaultErrorMessage
    })

export const axiosInstance = axios.create()

export const api = {
  get: (cardToken, paymentId, ccVerifyToken) => {
    const url = `/loyaltywebpublic/api/cardDetails/${cardToken}`
    return handleError(
      axiosInstance.get(url, {
        params: {
          paymentId,
          ccVerifyToken
        }
      })
    )
  },
  removeCard: (id, toastCardToken) => {
    // INFO: this endpoint currently returns a 302 on success of this call
    return axiosInstance
      .post('/loyaltywebpublic/creditcardremove', { id, toastCardToken })
      .catch(() => {
        throw defaultErrorMessage
      })
  },
  verifyCard: (id, toastCardToken, token) => {
    // INFO: this endpoint currently returns a 302 on success of this call
    return axiosInstance
      .post('/loyaltywebpublic/creditcardverify', {
        id,
        toastCardToken,
        token
      })
      .catch(() => {
        throw defaultErrorMessage
      })
  },
  updateUser: (cardToken, payload) => {
    const url = `/loyaltywebpublic/api/updateCustomer/${cardToken}`
    return handleError(axiosInstance.put(url, payload))
  },
  createWalletPass: (cardToken) => {
    return `/loyaltywebpublic/api/createWalletPass/${cardToken}`
  }
}
